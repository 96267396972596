import { IRoutePath } from '@/types/router';

export const footerLinks = [
  {
    title: 'Features',
    path: IRoutePath.FEATURES,
    items: [
      { title: 'Scheduling Software', path: IRoutePath.FEATURES_SCHEDULIG },
      { title: 'Estimates', path: IRoutePath.FEATURES_ESTIMATES },
      { title: 'Dispatching', path: IRoutePath.FEATURES_DISPATCHIG },
      { title: 'Invoicing', path: IRoutePath.FEATURES_INVOICING },
      {
        title: 'Sales Proposal Tool',
        path: IRoutePath.FEATURES_SALES_PROPOSAL,
      },
      {
        title: 'Field Service Management',
        path: IRoutePath.INDUSTRIES_FIELD_SERVICE,
      },
      {
        title: 'Employee GPS Tracking',
        path: IRoutePath.FEATURES_GPS_TRACKING,
      },
      // { title: 'Home Service Agreement', path: '#' },
      // { title: 'Job Managment', path: '#' },
      // { title: 'Quoting', path: '#' },
      // { title: 'Route Tracking', path: '#' },
    ],
  },
  {
    title: 'Industries',
    path: IRoutePath.INDUSTRIES,
    items: [
      { title: 'HVAC', path: IRoutePath.INDUSTRIES_HVAC },
      { title: 'Plumbing', path: IRoutePath.INDUSTRIES_PLUMBING },
      {
        title: 'Construction',
        path: IRoutePath.INDUSTRIES_CONSTRUCTION,
      },
      { title: 'Appliance Repair', path: IRoutePath.INDUSTRIES_APPLIANCE },
      { title: 'Electrician', path: IRoutePath.INDUSTRIES_ELECTRICIAN },
      //{ title: 'Garage Door', path: IRoutePath.INDUSTRIES_GARAGE_DOOR },
      { title: 'View All', path: IRoutePath.INDUSTRIES },
    ],
  },
  // {
  //   title: 'Industries',
  //   path: IRoutePath.INDUSTRIES,
  //   items: [
  //     {
  //       title: 'Property Maintenance',
  //       path: IRoutePath.INDUSTRIES,
  //     },
  //     {
  //       title: 'Junk Removal',
  //       path: IRoutePath.INDUSTRIES,
  //     },
  //     {
  //       title: 'Home Cleaning',
  //       path: IRoutePath.INDUSTRIES_HOME_CLEANING,
  //     },
  //     {
  //       title: 'Handyman',
  //       path: IRoutePath.INDUSTRIES_HANDYMAN,
  //     },
  //     {
  //       title: 'Field Service management',
  //       path: IRoutePath.INDUSTRIES_FIELD_SERVICE,
  //     },
  //     { title: 'View All', path: IRoutePath.INDUSTRIES },
  //   ],
  // },
  // {
  //   title: 'Solutions',
  //   path: IRoutePath.SOLUTIONS,
  //   items: [
  //     { title: 'Construction', path: '#' },
  //     { title: 'Commercial services', path: '#' },
  //     { title: 'Franchise management', path: '#' },
  //     { title: 'Field Service management', path: '#' },
  //   ],
  // },
  {
    title: 'Resources',
    path: IRoutePath.RECOURCES,
    items: [
      // { title: 'Pricing', path: IRoutePath.PRICING },
      { title: 'Blog', path: IRoutePath.BLOG },
      { title: 'Tutorial', path: IRoutePath.TUTORIAL },
      // { title: 'Help center', path: '#' },
      // { title: 'Tools', path: '#' },
    ],
  },
  {
    title: 'Company',
    path: IRoutePath.COMPANY,
    items: [
      { title: 'Contact us', path: IRoutePath.COMPANY_CONTACT_US },
      { title: 'About us', path: IRoutePath.COMPANY_UBOUR_US },
      {
        title: 'Privacy Policy',
        path: IRoutePath.COMPANY_POLICY,
      },
      // { title: 'Reviews', path: '#' },
      // { title: 'Become a partner', path: '#' },
    ],
  },
];
